const zh_CN = {
    hello: '你好，世界！',

    user_name: '姓名',
    user_role: '角色',
    user_email: '电邮',
    user_avatar: '头像',
    user_register: '注册',
    user_login: '登录',
    user_logout: '登出',
    user_password: '密码',
    user_passwordAgain: '再次输入密码',
    user_resetPassword: '重置密码',
    user_changePassword: '修改密码',
    user_sendPasswordResetEmail: '发送密码重置邮件',
    user_personalInfo: '个人信息',
    user_personalInfoUpdate: '个人信息修改',
    user_personalSecurity: '个人安全',
    user_oldPwd: '旧密码',
    user_newPwd: '新密码',
    user_newPwdAgain: '再次输入新密码',
    user_inconsist_pwd_twice: '两次输入的密码不一致',
    user_register_email_optional: '(可选，用于重置密码)',
    user_oauth_login: '使用Oauth登录',
    user_last_post_list: '最后30条post信息',

    cmn_author: '作者',
    cmn_created: '创建于',
    cmn_updated: '更新于',
    cmn_confirm: '确认',
    cmn_confirmUpdate: '确认更改',
    cmn_confirmDelete: '确认删除',
    cmn_cancel: '取消',
    cmn_home: '首页',
    cmn_verifyCode: '验证码',
    cmn_delete: '删除',
    cmn_insert: '插入',
    cmn_update: '更改',
    cmn_edit: '编辑',
    cmn_remove: '移除',
    cmn_yes: '是',
    cmn_no: '否',
    cmn_setting: '设置',
    cmn_select: '选择',

    cntnt_title: '标题',
    cntnt_content: '内容',
    cntnt_addPost: '发表主题',
    cntnt_addComment: '发表评论',
    cntnt_commentNum: '评论',
    cntnt_stickTop: '置顶',
    cntnt_already_stickTop: '已置顶',
    cntnt_cancelStickTop: '取消置顶',
    cntnt_submit: '提交',
    cntnt_comment: '评论',
    cntnt_like: '赞',    
    cntnt_last_reply: '最后回复',    
    cntnt_anonymous_publish: '匿名',    
    cntnt_this_is_me: '队长别开枪是我',    
    cntnt_this_is_anonymous: '暗夜幽灵',    

    adm_admin: '管理',

    ext_extend: '扩展',
    ext_choose: '选择',
    ext_vote: '投票',
    ext_lineup: '接龙',
    ext_you_choosed: '你选择了',
    ext_setup_lineup: '设置接龙',
    ext_allow_anonymous: '允许匿名',
    ext_choose_anonymous: '选择匿名',
    ext_if_allow_anonymous: '是否允许匿名',
    ext_expire_time: '截止时间',
    ext_expired: '已经截止',
    ext_not_expired: '未截止',
    ext_join_num: '参加人数',
    ext_setup_vote: '设置投票',
    ext_single_multiple: '单选/多选',
    ext_vote_single: '单选',
    ext_vote_multiple: '多选',
    ext_vote_options: '投票选项',
    ext_already_voted: '已投票',
    ext_already_lineupped: '已接龙',
    ext_quit_vote: '取消投票',
    ext_join_vote: '加入投票',
    ext_quit_lineup: '退出接龙',
    ext_join_lineup: '加入接龙',
    ext_lineup_message: '接龙留言',

    statistic_top_user: '最活跃用户',
    statistic_category_post_num: '板块占比',
  }
  export default zh_CN;
  