const en_US = {
    hello: 'Hello, world!',

    user_name: 'name',
    user_role: 'role',
    user_email: 'email',
    user_avatar: 'avatar',
    user_register: 'register',
    user_login: 'login',
    user_logout: 'logout',
    user_password: 'password',
    user_passwordAgain: 'password again',
    user_resetPassword: 'reset password',
    user_changePassword: 'change password',
    user_sendPasswordResetEmail: 'send password reset email',
    user_personalInfo: 'Personal Info',
    user_personalInfoUpdate: 'Personal Info Update',
    user_personalSecurity: 'Personal Security',
    user_oldPwd: 'old password',
    user_newPwd: 'new password',
    user_newPwdAgain: 'new password again',
    user_inconsist_pwd_twice: 'Inconsistent password entered twice',
    user_register_email_optional: '(optional. for reset password)',
    user_oauth_login: 'oauth login',
    user_last_post_list: 'last 30 posts',

    cmn_author: 'author',
    cmn_created: 'created',
    cmn_updated: 'updated',
    cmn_confirm: 'confirm',
    cmn_confirmUpdate: 'confirm update',
    cmn_confirmDelete: 'confirm delete',
    cmn_cancel: 'cancel',
    cmn_home: 'Home',
    cmn_verifyCode: 'verify code',
    cmn_delete: 'delete',
    cmn_insert: 'insert',
    cmn_update: 'update',
    cmn_edit: 'edit',
    cmn_remove: 'remove',
    cmn_yes: 'yes',
    cmn_no: 'no',
    cmn_setting: 'setting',
    cmn_select: 'select',

    cntnt_title: 'title',
    cntnt_content: 'content',
    cntnt_addPost: 'add post',
    cntnt_addComment: 'add comment',
    cntnt_commentNum: 'comments',
    cntnt_stickTop: 'top',
    cntnt_already_stickTop: 'topped',
    cntnt_cancelStickTop: 'cancel-top',
    cntnt_submit: 'submit',
    cntnt_comment: 'comment',
    cntnt_like: 'like',
    cntnt_last_reply: 'last-reply',
    cntnt_anonymous_publish: 'anonymous',    
    cntnt_this_is_me: `This's lovely me !`,
    cntnt_this_is_anonymous: 'The night ghost is me !',

    adm_admin: 'admin',

    ext_extend: 'extend',
    ext_choose: 'choose',
    ext_vote: 'Vote',
    ext_lineup: 'Lineup',
    ext_you_choosed: 'You choosed ',
    ext_setup_lineup: 'Lineup Setting',
    ext_allow_anonymous: 'allow anonymous',
    ext_choose_anonymous: 'choose anonymous',
    ext_if_allow_anonymous: 'allow anonymous?',
    ext_expire_time: 'expire time',
    ext_expired: 'expired',
    ext_not_expired: 'Not expired',
    ext_join_num: 'joined num',
    ext_setup_vote: 'Vote Setting',
    ext_single_multiple: 'single/multiple',
    ext_vote_single: 'single',
    ext_vote_multiple: 'multiple',
    ext_vote_options: 'vote options',
    ext_already_voted: 'voted',
    ext_already_lineupped: 'lineupped',
    ext_quit_vote: 'quit vote',
    ext_join_vote: 'join vote',
    ext_quit_lineup: 'quit lineup',
    ext_join_lineup: 'join lineup',
    ext_lineup_message: 'lineup message',

    statistic_top_user: 'Top Users',
    statistic_category_post_num: 'Category Proportion',
  }    
  export default en_US;
  