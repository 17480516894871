const ATTACH_ACTION = {

    ATTACH_STICK_TOP_SET: 'attach_stick_top_set',
    ATTACH_STICK_TOP_CANCEL: 'attach_stick_top_cancel',
    
    ATTACH_LIKE_SET: 'attach_like_set',
    ATTACH_LIKE_CANCEL: 'attach_like_cancel',
    
}

export default{
    ATTACH_ACTION,
}
