import styled from 'styled-components'

const StyledFieldSet = styled.fieldset`
    margin: 10px;
    // background-color: yellow;
    border: .5px solid gray;
    legend{
    }
`

export {
    StyledFieldSet,
}